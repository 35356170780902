import { FC, useEffect, useContext } from "react";

import { useRouter } from "next/router";
import { useTracking } from "../hooks/useTracking";
import UserContext from "../contexts/UserContext";

const Login: FC = () => {
  const { trackEvent } = useTracking();
  const { user } = useContext(UserContext);
  const router = useRouter();
  useEffect(() => {
    trackEvent("login", {
      userId: user?.id,
    });
    window.location.href = process.env.NEXT_PUBLIC_LOGIN_URL as string;
  }, [router, trackEvent, user?.id]);
  return null;
};

export default Login;
